import {
  Layout,
  Hero,
  Main,
  FeatureSection,
  MembershipSection,
  TestimonialSection,
  // CarouselSection,
} from 'components';

const Index = () => {
  return (
    <Layout title='Home' description='Maisie Beauty Spa Salon Homepage'>
      <Hero />
      <Main>
        <FeatureSection />
        <TestimonialSection />
        <MembershipSection />
        {/* <CarouselSection /> */}
      </Main>
    </Layout>
  );
};

export default Index;
